.vacationOverviewGrid {
  .react-bootstrap-table-pagination {
    margin-top: 75px;
    position: absolute;
    width: 100%;
    z-index: 1000;
    padding-right: 13px;
  }

  .td-column-white {
    background-color: #fff;
    color: black;
    font-weight: normal;
  }

  .td-column-grey-columns {
    background-color: $hrm-table-td-column-grey-header;
    color: black;
    font-weight: normal;
  }

  .td-column-medium-grey {
    background-color: $hrm-table-td-column-medium-grey;
    color: white;
    font-weight: normal;
  }

  .td-column-dark-grey {
    background-color: $hrm-table-td-column-dark-grey;
    color: white;
    font-weight: bold;
  }

  .td-column-totalNoOfDays {
    background-color: #3a3838;
    color: white;
  }
  .td-column-lightgrey {
    background-color: $hrm-table-td-column-light-grey;
    color: black;
  }

  .td-column-otherSpecialColumns {
    background-color: $hrm-table-td-column-otherSpecialColumns;
    color: white;
  }

  .td-column-normal {
    font-size: 0.75rem;
    background-color: $hrm-table-td-column-dark-grey;
    color: white;
    font-weight: bold;
  }

  .td-column-available-vacation {
    background-color: $hrm-table-td-column-light-grey;
    color: black;
    font-weight: bold;
  }

  .td-column-black {
    background-color: $hrm-table-td-column-black;
    color: black;
    font-weight: bold;
  }

  .td-column-totalVacation {
    background-color: $hrm-table-td-column-medium-grey;
    color: white;
  }

  .td-column-grey-header {
    background-color: $hrm-table-td-column-grey-header;
    color: black;
    font-weight: bold;
  }

  .td-column-green {
    background-color: $computas-green;
    color: white;
    font-weight: bold;
  }

  .td-column-light-grey {
    background-color: $hrm-table-td-column-light-grey;
    color: black;
    font-weight: normal;
  }

  .table > thead > tr > th {
    line-height: 1.2;
    overflow-x: hidden;
    white-space: normal;
    text-overflow: unset;
    font-size: 0.7rem;
  }

  .react-bs-table-container,
  .react-bootstrap-table-container {
    overflow-x: scroll;
  }

  .react-bs-table,
  .vacationOverviewTable,
  .vacationOverviewTableTotals {
    min-width: 3500px;
  }

  .vacationOverviewTableTotals {
    .table-bordered > tbody > tr:nth-last-child(-n + 2) > td {
      &:nth-last-child(n) {
        border: 1px solid black;
        background-color: $hrm-table-td-column-dark-grey;
        color: #fff;
        font-weight: bold;
      }
    }

    .table-bordered > tbody > tr:nth-last-child(-n + 1) > td {
      &:nth-child(n) {
        border: 1px solid black;
        background-color: $computas-green;
        color: #fff;
      }
    }
  }

  .react-bootstrap-table-container > table > thead > tr > th {
    border: 1px solid black;
    vertical-align: middle;
    text-align: center;
  }

  .react-bootstrap-table-container {
    td {
      font-size: 0.75rem;
    }
  }

  .table-bordered > tbody > tr > td {
    border: 1px solid black;
  }
}
