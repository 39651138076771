.statistics .col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding-right: 2px;
}

.statistics .left {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.statistics .right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.redNumber {
  font-size: 23px;
  font-weight: bold;
  color: $computas-red;
}

.greenNumber {
  font-size: 23px;
  font-weight: bold;
  color: $computas-green;
}

.blackNumber {
  font-size: 23px;
  font-weight: bold;
  color: black;
}

.blueNumber {
  font-size: 23px;
  font-weight: bold;
  color: $computas-blue;
}
.yellowNumber {
  font-size: 23px;
  font-weight: bold;
  color: $computas-light-green;
}

.number {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  float: left;
  margin-right: 10px;
  height: 100%;
}

.boldText {
  font-weight: bold;
}
.titelOfficeLeaves {
  font-weight: bold;
  margin-left: -5px;
}

.italicOfficeLeaveText {
  font-style: italic;
  height: 100%;
}

.flexDivCentered {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
