@page {
  size: auto;
  margin: 0mm;
}

.printOfficeLeave {
  margin: 5vw 7vw 5vw;
}

.printOfficeLeave .borderedContent {
  border: 0.1vw #000 solid;
  width: 100%;
  height: 63vw;
  margin-bottom: 5vw;
}

.printOfficeLeave .leftNoBorder {
  border-right: 0px;
}

.printOfficeLeave .col-xs-6 {
  padding-left: 0px;
  padding-right: 0px;
}

.printOfficeLeave .title {
  margin-top: 7vw;
  margin-bottom: 7vw;
  font-size: 2.5vw;
  color: #365f91 !important;
  -webkit-print-color-adjust: exact;
  text-align: center;
  font-weight: 700;
}

.printOfficeLeave .address {
  padding-left: 1.35vw;
  padding-right: 1.35vw;
  padding-bottom: 5.6vw;
}

.printOfficeLeave .content {
  padding-left: 1.35vw;
  padding-right: 1.35vw;
  padding-bottom: 0.4vw;
  text-align: justify;
}

.printOfficeLeave .undersigned {
  padding-left: 6vw;
}

.rootPrintOfficeLeave {
  font-size: 2vw;
}

.rootPrintOfficeLeave .marginBottom50 {
  margin-bottom: 4vw;
}

.rootPrintOfficeLeave .marginBottom15 {
  margin-bottom: 1.5vw;
}

.rootPrintOfficeLeave .marginBottom25 {
  margin-bottom: 2vw;
}

.rootPrintOfficeLeave .blankName {
  width: 8vw;
  border-bottom: 0.1vw solid black;
  display: inline-block;
  margin-right: 0.4vw;
}

.rootPrintOfficeLeave .footerNotes {
  margin-left: 8vw;
}
.rootPrintOfficeLeave .printOfficeLeave {
  margin-left: 90px;
  margin-right: 90px;
}

.printOfficeLeave .vacationRequest {
  margin-top: 120px;
}

.footerOfficeLeavePrint .footerCenterDiv {
  border-left: 0.2vw #000 solid;
  border-right: 0.2vw #000 solid;
  padding: 1.75vw 0 1.4vw 1vw;
}

.footerOfficeLeavePrint .footerRightDiv {
  padding: 3vw 0 2vw 1vw;
}

.footerOfficeLeavePrint .footerLeftDiv {
  padding-top: 1.5vw;
  padding-left: 3vw;
}

.printOfficeLeave .pageTitle {
  padding: 0 1vw 2.25vw 1vw;
}

.footerOfficeLeavePrint {
  font-size: 1.7vw;
  line-height: 3vw;
  position: absolute;
  bottom: 20px;
  height: 120px;
  width: 100%;
}
