.react-bootstrap-table-pagination {
  margin-right: -2px;
  margin-top: 10px;
}

.table-bordered td {
  text-overflow: ellipsis;
}

.officeLeavePlanTables {
  .react-bootstrap-table tr.expanding-row {
    background-color: #f8f8f8;
  }
}

.timesheetGrid {
  margin-bottom: 60px;
  .react-bootstrap-table-pagination {
    margin-top: 45px;
    position: absolute;
    width: 100%;
    z-index: 1000;
    padding-right: 13px;
  }
}

.number-filter-comparator {
  width: 45px !important;
}

.number-filter-input {
  width: calc(100% - 45px - 5px) !important;
}

.select-filter {
  font-size: 0.78rem;
  padding: 0.2rem 0.2rem;
}
