.genericLoadingAnimation {
  position: fixed;
  background-color: rgba(192, 192, 192, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  top: 0px;
  left: 0px;
  min-height: 100%;
  height: 100%;
  width: 100%;
}

.loadingAnimationTop {
  top: 53px;
}

body {
  background-color: $white;
}

.loader {
  position: relative;
  margin: 0 auto;
  width: 100px;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $computas-dark-blue;
  }
  40% {
    stroke: $computas-red;
  }
  66% {
    stroke: $computas-green;
  }
  80%,
  90% {
    stroke: $computas-pantone;
  }
}
