.hrmCalendar {
  height: inherit;

  .DayPicker-wrapper {
    background-color: #f8f8f8;
  }

  .DayPicker-WeekNumber,
  .DayPicker-Day {
    padding: 0rem;
    border-radius: 0 !important;
    height: 4vmax;
  }

  .DayPicker-NavButton {
    outline: none;
    &--prev {
      left: 1.5rem;
      right: auto;
    }

    &--next {
      right: 1.5rem;
      left: auto;
    }
  }

  .cellRelative {
    height: 55%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 1.25rem;
    position: relative;
  }

  .innerCell {
    cursor: default;
    color: black;
    position: absolute;
    left: 0; //IE fix
    top: 0px;
    width: 100%;
    box-shadow: 1px 1px 5px #888888;
    top: 4px;
    height: -moz-calc(100% - (8px));
    height: -webkit-calc(100% - (8px));
    height: calc(100% - (8px));
    opacity: 0.95;
    border-top: 4px solid $computas-blue;
    border-bottom: 4px solid $computas-blue;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: #fff;
    background-color: $computas-dark-blue;
    border: $computas-blue;
    border-width: initial;
    border-style: initial;
    border-color: $computas-blue;
    border-image: initial;
  }

  .DayPicker-TodayButton {
    color: $computas-dark-blue;
  }

  .DayPicker-TodayButton {
    margin-top: 10px;
    color: $computas-dark-blue;
    font-size: 0.89em;
    outline-color: white;
  }

  .DayPicker-Day--delegation {
    .Delegation--innerCell {
      border-top: 4px $gray-400 solid;
      border-bottom: 4px $gray-400 solid;
      z-index: 5;
    }
  }

  .DayPicker-Day--workFromHome {
    .WorkFromHome--innerCell {
      background-color: $computas-yellow;
      border: none;
      z-index: 3;
    }
  }

  .DayPicker-Day--publicHoliday {
    .PublicHoliday--innerCell {
      background-color: silver;
      border: none;
      color: black;
      z-index: 4;
    }
  }

  .DayPicker-Day--vacation {
    .Vacation--innerCell {
      background-color: $success;
      border: none;
      z-index: 2;
    }
  }

  .DayPicker-Day--vacationFromAccumulated {
    .VacationFromAccumulated--innerCell {
      background-color: $computas-light-green;
      border: none;
      z-index: 2;
    }
  }

  .DayPicker-Day--specialEvent {
    .SpecialEvent--innerCell {
      background-color: $computas-red;
      border: none;
      z-index: 1;
    }
  }

  .DayPicker-Day--travel {
    .Travel--innerCell {
      background-color: $computas-blue;
      border: none;
      z-index: 1;
    }
  }

  .DayPicker-Day--today {
    color: black;
  }

  .leftCell {
    border-top-left-radius: 25% 50%;
    border-bottom-left-radius: 25% 50%;
    left: 20%;
    width: 80%;
  }

  .rightCell {
    border-top-right-radius: 25% 50%;
    border-bottom-right-radius: 25% 50%;
    right: 20%;
    width: 80%;
  }

  .rightLeftCell {
    border-top-left-radius: 25% 50%;
    border-bottom-left-radius: 25% 50%;
    left: 20%;
    right: 20%;
    width: 60%;
  }

  .PlannedVacation--leftCell {
    border-left: 4px $computas-blue solid;
    border-bottom: 4px $computas-blue solid;
    border-top: 4px $computas-blue solid;
    left: calc(20% - 3px);
    width: calc(80% + 3px);
  }

  .PlannedVacation--rightCell {
    border-right: 4px $computas-blue solid;
    border-top: 4px $computas-blue solid;
    border-bottom: 4px $computas-blue solid;
    right: calc(20% - 3px);
    width: calc(80% + 3px);
  }

  .PlannedVacation--rightLeftCell {
    right: calc(20% - 3px);
    width: calc(60% + 6px);
    left: calc(20% - 3px);
  }

  .Delegation--leftCell {
    border-left: 4px $gray-400 solid;
  }

  .Delegation--rightCell {
    border-right: 4px $gray-400 solid;
  }

  .WorkFromHome--tooltipCell {
    background-color: $computas-yellow;
  }

  .PlannedVacation--tooltipCell {
    border: 4px $computas-blue solid;
  }

  .Vacation--tooltipCell {
    background-color: $success;
  }

  .VacationFromAccumulated--tooltipCell {
    background-color: $computas-light-green;
  }

  .PlannedDays--tooltipCell {
    background-color: $computas-dark-blue;
  }

  .Delegation--tooltipCell {
    border: 4px $gray-400 solid;
  }

  .SpecialEvent--tooltipCell {
    background-color: $computas-red;
  }

  .Travel--tooltipCell {
    background-color: $computas-blue;
  }

  .PublicHoliday--tooltipCell {
    background-color: silver;
  }

  .DayPicker .DayPicker {
    &-Month {
      width: 95%;
      min-width: 95%; //IE fallback
      table-layout: fixed;
    }

    &-Months {
      width: 100%;
    }

    &-wrapper {
      height: 100%;
      background-color: white;
    }
  }

  .calendarIcon {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
    border-radius: 50%;
  }

  .cellDay {
    position: relative;
    z-index: 11;
  }
}

.dashboardCalendar {
  @extend .hrmCalendar;
  height: 100%;

  > div {
    padding-left: 30px;
  }

  .calendarBorderNone {
    border: none;
  }

  .calendarSize {
    height: 100%;
    position: relative;
    border-right: 1px solid lightgray;
  }

  .Select-menu-outer {
    z-index: 100;
  }

  .DayPicker-NavButton {
    width: 40px;
    height: 26px;
    position: relative;
    top: 0;

    &--prev {
      margin-right: 0;
      left: 0;
      right: auto;
    }

    &--next {
      right: 0em;
      left: auto;
    }
  }

  .month-font {
    font-size: 1.2rem;
    cursor: pointer;
  }

  .DayPicker-Footer {
    text-align: center;
    font-size: 2.5rem;
    padding-top: 0px;
  }

  .DayPicker-WeekNumber,
  .DayPicker-Day {
    height: 110px; //IE fallback
    height: 6vmax;
  }
}

.dashboardCalendar .DayPicker-Month {
  background-color: white;
}

.planCalendar {
  @extend .hrmCalendar;
  height: 45vh;
  min-height: 400px;
  max-height: 600px;
  margin-bottom: 20vh;

  .publicHoliday {
    background-color: $computas-red;
    border-radius: 30px;
    border: 2px solid black;
    color: white;
  }

  .vacation {
    background-color: $computas-green;
    border-radius: 30px;
    border: 2px solid black;
    color: white;
  }

  .DayPicker-Day {
    &--publicHolidays {
      cursor: default;
      background-color: #eff1f1;
    }

    &--vacation {
      cursor: default;
      background-color: #eff1f1;
    }
  }

  .DayPicker-Caption {
    font-size: 1.25rem;
    font-weight: bolder;
    margin-bottom: 20px;
    text-align: center;
  }

  .DayPicker-NavButton {
    width: 3rem;
    height: 3rem;
  }

  .DayPicker-Weekday {
    font-size: 1.5rem;
  }

  .DayPicker-Footer {
    text-align: center;
    font-size: 2rem;
  }

  .cellRelative {
    font-size: 1.25rem;
    height: 65%;
  }

  .DayPicker-wrapper {
    height: 100%;
    padding-bottom: 20px;
  }

  .legend {
    background-color: #f8f8f8;
    padding-left: 20px;
  }

  .legendTopPadding {
    padding-top: 10px;
  }
}

@media (max-width: 768px) {
  .dashboardCalendar .calendarSize {
    border: none;
  }
}

.DayPicker-Day--disabled {
  background-color: #eff1f1;
  color: #dce0e0;
}

.DayPicker-Caption .col-xs-2 {
  padding-left: 2px;
}
