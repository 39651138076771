.divLogWorkFilter {
  margin-top: 10px;
  padding-bottom: 50px !important;
  padding-top: 10px;
}

.workLogDatePicker {
  z-index: 100;
  position: relative;
  margin-top: 10px;
}

.workLogTextStyle {
  height: 27px;
  font-size: 25px;
  cursor: pointer;
}

.workLogTextStyle:hover {
  font-size: 26px;
}

.workLogFixHeight {
  height: 28px;
}
