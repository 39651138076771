.expand {
    &-enter {
        max-height: 1px;

        &.expand-enter-active {
            max-height: 500px;
            transition: max-height 300ms ease-in;
        }
    }

    &-leave {
        max-height: 500px;

        &.expand-leave-active {
            max-height: 1px;
            overflow: hidden;
            transition: max-height 300ms ease-in;
        }
    }
}