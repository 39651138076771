.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
  background-color: $computas-dark-blue;
}

.DayPickerInput {
  .form-control:disabled,
  .form-control[readonly] {
    background-color: unset;
  }

  .close {
    z-index: 10;
    pointer-events: auto;
    cursor: pointer;
    position: absolute;
    right: 5px;
    display: block;
    line-height: 27px;
    text-align: center;
    user-select: none;
  }
}

.custom-range-picker {
  .DayPickerInput-OverlayWrapper {
    position: unset;
    margin-left: 10px;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
    background-color: $computas-yellow;
    color: #4a90e2;
  }

  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
    background-color: $computas-dark-blue !important;
  }

  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
    background-color: $computas-dark-blue !important;
  }

  .DayPickerInput-Overlay {
    margin-left: 15px;
  }
}

.DayPicker-Day {
  border-radius: 0 !important;
  border: 1px solid $gray-300;
}

.DayPicker-Day:focus {
  outline: none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #fff;
  background-color: $computas-dark-blue;
}

.DayPicker-wrapper:focus {
  outline: none;
}

.DayPickerInput-Overlay {
  z-index: 1000;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: $computas-green !important;
}

.custom-caption-calendar-navigation {
  .react-select-container {
    font-size: 0.75rem;
    font-weight: normal;

    .react-select__indicator {
      padding: 0;
    }

    .react-select__indicator-separator {
      margin: 0;
    }

    .react-select__control--is-focused,
    .react-select__control {
      min-height: unset;
    }

    .react-select__value-container {
      padding: 0px 8px;
    }
  }
}
