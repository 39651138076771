#notificationContainer {
  overflow: visible;
  z-index: 10;

  .notificationTitle {
    font-weight: 700;
    padding: 8px;
    background-color: #ffffff;
    border-bottom: 1px solid #dddddd;
    color: black;
    text-align: right;
    font-size: 0.9em;
  }

  #notificationFooter {
    background-color: #e9eaed;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    border-top: 1px solid #dddddd;
    padding: 2px 0px;
  }

  #notificationsBody {
    max-height: 250px;
    overflow-y: auto;

    .notification-item {
      padding: 5px 10px;
      margin: 0px;
      background: white;
      border-radius: 0;
      min-width: 350px;
      max-width: 450px;
      text-decoration: none;
      line-height: 0.9rem;

      color: black;

      .item-info {
        word-wrap: break-word;
        text-align: left;
        white-space: normal;
        font-size: 0.8em;
      }

      .item-timestamp {
        color: grey;
        font-size: 0.75em;

        &:hover {
          color: $white;
        }
      }

      &:hover {
        background: $computas-green;
        color: $white;
        .item-timestamp {
          color: $white;
        }
      }
    }
  }
}

#notificationContainer,
#notificationContainer *:focus {
  outline: none !important;
}

.pendingRequestsDropdown {
  .dropdown-item {
    padding: 0;
  }

  .dropdown-menu {
    padding: 0px 0px !important;
    left: -315px;
  }

  .dropdown-menu > li > a {
    padding: 0 !important;
  }
}
