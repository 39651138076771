.workLogModal{

    .DayPickerInput-OverlayWrapper{
        z-index: 1071;
    }

    .rc-time-picker-input{
        border-radius: 0;
        border: none;
        height: 100%;
    }

    .DayPickerInput{
        width: 105px;
    }
}

.rc-time-picker-panel{
    width: 115px!important;
}